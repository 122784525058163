import { graphql } from "gatsby";
import * as React from "react";
import Layout from "~/templates/Layout";
import useBlogPosts from '~/hooks/useBlogPosts';
import Seo from 'gatsby-plugin-wpgraphql-seo';
import { ResourceCard } from "../components/landing/Resources";
import { UnderlineLink, LinkInner } from '~/components/elements/Link';
import { Newsletter } from "~/components/flexible/Newsletter";

export default function categoryPage({
  data: { wpPage, wp, wpCategory },
}: {
  data: { wpPage: { page } };
}) {


  return (
    <Layout wp={wp} >
      <Seo post={wpCategory} title={wpCategory.name} />
      <Masthead heading={wpCategory.name} />
      <Resources {...wpCategory} />
      <Newsletter />
    </Layout>
  );
}

export const Masthead = ({ heading, content }) => {
	return (
		<section className="section section-top">
			<div className="container">
				<div className="max-w-[1002px] mx-auto text-center space-y-5">
					<h1 className="t-75">{heading}</h1>
          <LinkInner link={{title: 'Back to resources', url: '/resources'}} type="underline" />
				</div>
			</div>
		</section>
	)
}

export const Resources = (props) => {

  const { posts, id } = props

  const { allWpPost } = useBlogPosts();

  const results = allWpPost.nodes.filter(({ categories }) => categories.nodes[0].id === id)

  return (
    <section className="section section-bot md:-mt-10 ">
      <div className="container">
        <div className="grid grid-cols-1 gap-6 md:grid-cols-2">
          {results?.map((result, i ) => (
            <ResourceCard result={result} category={props} />
          ))}
        </div>
      </div>
    </section>
  )
}

export const pageQuery = graphql`
  query Category($id: String!) {
    wpPage(id: { eq: $id }) {
      ...SEO
      ...GeneratedWpPage
    }
    wp {
      ...GeneratedWp
    }
    wpCategory(id: { eq: $id }) {
      ...GeneratedWpCategory
    }
  }
`;
