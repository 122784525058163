import React, { useEffect } from 'react';
import { SEOContext } from 'gatsby-plugin-wpgraphql-seo';
import useSiteInfoAssets from '~/hooks/useSiteInfoAssets';
import Footer from '~/components/global/Footer';
import Header from '~/components/global/Header';
import { MaybeLink } from '~/components/elements/Link';
import { ThinCross } from '~/components/elements/Icon';
import { StaticImage } from 'gatsby-plugin-image';
import { useState } from 'react';
import { useCookies } from "react-cookie";
import { getBgClass } from '~/components/layouts/Section';
import useDocumentScrollThrottled from "~/hooks/useDocumentScrollThrottled";

export const OptionsContext = React.createContext();

export default function Layout({ wp, children, headerColor, backgroundColour, headerType, hideFooter = false, page }) {
  const seo = useSiteInfoAssets();

  const mainClasses = ['']

  const bgColour = getBgClass(backgroundColour)
  mainClasses.push(bgColour)


  return (
    <OptionsContext.Provider value={{ options: wp, page }}>
      <SEOContext.Provider value={{ global: seo }}>
        <Header
          page={page}
          data={wp?.header}
          headerColor={headerColor}
          headerType={headerType}
        />
        <main className={mainClasses.join(' ')}>{children}</main>
        {!hideFooter && (
          <Footer data={wp?.footer} />
        )}
        <Popup />

      </SEOContext.Provider>
    </OptionsContext.Provider>
  );
}

export const Popup = () => {

  const [show, setShow] = useState(false)
  const [shown, setShown] = useState(false)
  const [cookies, setCookie, removeCookie] = useCookies(['hide-popup']);


  useDocumentScrollThrottled((callbackData) => {
    if (typeof window === "undefined" || shown) return;

    const { previousScrollTop, currentScrollTop } = callbackData;
    const isMinimumScrolled = currentScrollTop > 0;

    if (cookies["hide-popup"] !== 'true') {
      setTimeout(() => {
        setShown(isMinimumScrolled);
        setShow(isMinimumScrolled)
      }, 1000)
      
    }

  });


  const handleClose = () => {
    setCookie('hide-popup', true)
    setShow(false)
    setShown(true)
  }


  return (
    <div className={`fixed z-50 bottom-5 md:bottom-12 right-5 md:right-[73px] w-[277px] py-4 px-6 bg-blue text-white rounded-[11px] space-x-[14px] flex items-center transform transition-opacity duration-300 ease-in-out opacity-0 pointer-events-none  ${show && 'opacity-100 pointer-events-auto'}`}>
      <MaybeLink link={{ url: 'tel:844-903-2070' }} className="flex items-center space-x-[14px]">
        <StaticImage src="../assets/images/popup-icon.png" alt="" className="w-10" />

        <div className="text-[15px]">
          <span className="block">Speak with a Specialist </span>
          <div className="font-semibold underline">844-903-2070</div>
        </div>

      </MaybeLink>


      <button className="absolute top-2 right-2 w-5 h-5 border-[#474545] border-opacity-20 text-white flex items-center justify-center" onClick={handleClose}>
        <ThinCross />
      </button>

    </div>
  )
}
